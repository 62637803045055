import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';

const ContactQueryDetailHeader = () => {
	return (
		<Header>
			<HeaderLeft className='h4 mb-0 fw-bold'>Contact-Query Detail</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default ContactQueryDetailHeader;
