import adminApi from '../adminApi';

export interface AdminLogin {
	id: string;
	email: string;
	token: string | null;
	password: string;
	role: string;
}

export interface UpdateProfileRequestData {
	firstName: string;
	lastName: string;
	profileImage: string | null;
}

export interface ChangePasswordData {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const AuthApi = adminApi.injectEndpoints({
	endpoints: (builder: any) => ({
		postLogin: builder.mutation({
			query: (payload: AdminLogin) => ({
				url: 'admin/login',
				method: 'POST',
				body: payload,
			}),
		}),
		getProfile: builder.mutation({
			query: () => ({
				url: 'admin/profile',
				method: 'GET',
			}),
		}),
		updateProfile: builder.mutation({
			query: (payload: UpdateProfileRequestData) => ({
				url: 'admin/profile',
				method: 'PUT',
				body: payload,
			}),
		}),
		changePassword: builder.mutation({
			query: (payload: ChangePasswordData) => ({
				url: 'admin/change-password',
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

export const {
	usePostLoginMutation,
	useGetProfileMutation,
	useUpdateProfileMutation,
	useChangePasswordMutation,
} = AuthApi;
