import React from 'react';
import Spinner from './bootstrap/Spinner';

const Loader = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				fontSize: '18px',
			}}>
			<Spinner size={40} color='info' />
			Loading...
		</div>
	);
};

export default Loader;
