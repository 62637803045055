import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';

const ServiceDetailHeader = () => {
	return (
		<div className='z-0'>
			<Header>
				<HeaderLeft className='h4 mb-0 fw-bold'>Service Detail</HeaderLeft>
				<CommonHeaderRight />
			</Header>
		</div>
	);
};

export default ServiceDetailHeader;
