import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from './store';

import { USER_SLICE_STATE } from './types';

const initialState: USER_SLICE_STATE = {
	token: null,
	adminData: null,
	userManagementCurrentPage: 1,
	massageManagementCurrentPage: 1,
	categoryCurrentPage: 1,
	requestedMassageCurrentPage: 1,
	topRatedMassageCenterCurrentPage: 1,
	featureManagementCurrentPage: 1,
	slotManagementCurrentPage: 1,
	featuredServiceCurrentPage: 1,
	masseuseListCurrentPage: 1,
	servicesListCurrentPage: 1,
	orderListCurrentPage: 1,
	invoicesListCurrentPage: 1,
	promotionsListCurrentPage: 1,
	sendNotificationCurrentPage: 1,
	faqsListCurrentPage: 1,
	contactQueriesListCurrentPage: 1,
	notificationCurrentPage: 1,
	configureMassageCenterCurrentPage: 1,
	userManagementTab: '1',
	massageCenterTab: '1',
};

export const adminSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		setAdminData: (state, action: PayloadAction<any | null | undefined>) => {
			const data = action.payload;
			state.adminData = data ?? null;
			state.token = data?.data.token ?? null;
		},
		setUserManagementCurrentPage: (state, action: PayloadAction<number>) => {
			state.userManagementCurrentPage = action.payload;
		},
		setMassageManagementCurrentPage: (state, action: PayloadAction<number>) => {
			state.massageManagementCurrentPage = action.payload;
		},
		setCategoryCurrentPage: (state, action: PayloadAction<number>) => {
			state.categoryCurrentPage = action.payload;
		},
		setRequestedMassageCurrentPage: (state, action: PayloadAction<number>) => {
			state.requestedMassageCurrentPage = action.payload;
		},
		setTopRatedMassageCenterCurrentPage: (state, action: PayloadAction<number>) => {
			state.topRatedMassageCenterCurrentPage = action.payload;
		},
		setFeatureManagementCurrentPage: (state, action: PayloadAction<number>) => {
			state.featureManagementCurrentPage = action.payload;
		},
		setSlotManagementCurrentPage: (state, action: PayloadAction<number>) => {
			state.slotManagementCurrentPage = action.payload;
		},
		setFeaturedServiceCurrentPage: (state, action: PayloadAction<number>) => {
			state.featuredServiceCurrentPage = action.payload;
		},
		setMasseuseListCurrentPage: (state, action: PayloadAction<number>) => {
			state.masseuseListCurrentPage = action.payload;
		},
		setServicesListCurrentPage: (state, action: PayloadAction<number>) => {
			state.servicesListCurrentPage = action.payload;
		},
		setOrderListCurrentPage: (state, action: PayloadAction<number>) => {
			state.orderListCurrentPage = action.payload;
		},
		setInvoicesListCurrentPage: (state, action: PayloadAction<number>) => {
			state.invoicesListCurrentPage = action.payload;
		},
		setPromotionListCurrentPage: (state, action: PayloadAction<number>) => {
			state.promotionsListCurrentPage = action.payload;
		},
		setFAQsListCurrentPage: (state, action: PayloadAction<number>) => {
			state.faqsListCurrentPage = action.payload;
		},
		setContactQueriesListCurrentPage: (state, action: PayloadAction<number>) => {
			state.contactQueriesListCurrentPage = action.payload;
		},
		setNotificationCurrentPage: (state, action: PayloadAction<number>) => {
			state.notificationCurrentPage = action.payload;
		},
		setSendNotificationCurrentPage: (state, action: PayloadAction<number>) => {
			state.sendNotificationCurrentPage = action.payload;
		},
		setConfigureMassageCenterCurrentPage: (state, action: PayloadAction<number>) => {
			state.configureMassageCenterCurrentPage = action.payload;
		},
		setUserManagementTab: (state, action: PayloadAction<string>) => {
			state.userManagementTab = action.payload;
		},
		setMassageCenterTab: (state, action: PayloadAction<string>) => {
			state.massageCenterTab = action.payload;
		},
		setLogout: () => {
			return initialState;
		},
	},
});

export const {
	setAdminData,
	setLogout,
	setUserManagementCurrentPage,
	setCategoryCurrentPage,
	setMassageManagementCurrentPage,
	setRequestedMassageCurrentPage,
	setTopRatedMassageCenterCurrentPage,
	setFeatureManagementCurrentPage,
	setSlotManagementCurrentPage,
	setFeaturedServiceCurrentPage,
	setMasseuseListCurrentPage,
	setServicesListCurrentPage,
	setOrderListCurrentPage,
	setInvoicesListCurrentPage,
	setPromotionListCurrentPage,
	setFAQsListCurrentPage,
	setContactQueriesListCurrentPage,
	setNotificationCurrentPage,
	setSendNotificationCurrentPage,
	setConfigureMassageCenterCurrentPage,
	setUserManagementTab,
	setMassageCenterTab,
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;

export const token = (state: RootState) => state?.admin?.token;
